<template>
  <div class="loader">
    <div class="loader__block">

    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
.loader{
  position: fixed;
  display: flex;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,0.3);
  z-index: 100000;
  &__block{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: $blue;
    animation: loader 1s infinite;
  }
}
@keyframes loader {
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(2);
  }
  100%{
    transform: scale(1);
  }
}
</style>
